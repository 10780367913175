(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

$(function () {
  $(window).on("load", function () {
    $(".sameheight").height(Math.max.apply(null, $(".sameheight1").map(function () {
      return $(this).height();
    })));
    $(".equalheight1").height(Math.max.apply(null, $(".equalheight1").map(function () {
      return $(this).height();
    })));
    $(".equalheight2").height(Math.max.apply(null, $(".equalheight2").map(function () {
      return $(this).height();
    })));
    $(".equalheight3").height(Math.max.apply(null, $(".equalheight3").map(function () {
      return $(this).height();
    })));
    $(".equalheight4").height(Math.max.apply(null, $(".equalheight4").map(function () {
      return $(this).height();
    })));
    $(".equalheight5").height(Math.max.apply(null, $(".equalheight5").map(function () {
      return $(this).height();
    })));
    $(".welcome4").height(Math.max.apply(null, $(".welcome4").map(function () {
      return $(this).height();
    })));
    $(".welcome3").height(Math.max.apply(null, $(".welcome3").map(function () {
      return $(this).height();
    })));
    $(".welcomeside").height(Math.max.apply(null, $(".welcomeside").map(function () {
      return $(this).height();
    })));
  });

  $('.deletepose').click(function () {
    $('#pose').val('');
    loadIndex();
  });
  $('.deletebase').click(function () {
    $('#base').val(0);
    loadIndex();
  });
  $('.deleteflyer').click(function () {
    $('#flyer').val(0);
    loadIndex();
  });
  $('.deleteconnectpoint').click(function () {
    $('#connectpoint').val(0);
    loadIndex();
  });
  $('.deletetrans').click(function () {
    $('#transitiontitel').val('');
    loadIndex();
  });
  $('.deletelevel').click(function () {
    $('#level').val(0);
    loadIndex();
  });
  $('.deletepose1').click(function () {
    $('#pose1').val('');
    loadIndex();
  });
  $('.deletebase1').click(function () {
    $('#base1').val(0);
    loadIndex();
  });
  $('.deleteflyer1').click(function () {
    $('#flyer1').val(0);
    loadIndex();
  });
  $('.deleteconnectpoint1').click(function () {
    $('#connectpoint1').val(0);
    loadIndex();
  });
  $('.deletepose2').click(function () {
    $('#pose2').val('');
    loadIndex();
  });
  $('.deletebase2').click(function () {
    $('#base2').val(0);
    loadIndex();
  });
  $('.deleteflyer2').click(function () {
    $('#flyer2').val(0);
    loadIndex();
  });
  $('.deleteconnectpoint2').click(function () {
    $('#connectpoint2').val(0);
    loadIndex();
  });

  constructName();
  $('select').change(function () {
    constructName();
  });
  $('#nameextension').keyup(function () {
    constructName();
  });

  function constructName() {
    var prev = $('#prev option:selected').text();
    var next = $('#next option:selected').text();
    var extend = $('#nameextension').val();

    var title = prev + ' to ' + next;

    if (next == 'END') {
      title = 'Exit from ' + prev;
    }
    if (prev == 'START') {
      title = 'Entry to ' + next;
    }
    title = title + ' ' + extend;
    $('#title').val(title);
    $('h2[name=title]').html(title);
    //  console.log($('#title').html());
  }

  $(document).ready(function () {
    $(".gif").hover(function () {
      var src = $(this).attr("src");
      $(this).attr("src", src.replace("thumbnail", "original"));
      //  console.log($(this).attr("src"));
    }, function () {
      var src = $(this).attr("src");
      $(this).attr("src", src.replace("original", "thumbnail"));
      //  console.log($(this).attr("src"));
    });
  });

  tinymce.init({
    selector: "textarea.editor",
    oninit: "setPlainText",
    mode: "exact",
    elements: "elm1",
    plugins: ['paste'],
    toolbar: " insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ",
    menu: {},
    style_formats: [{ title: 'Header', block: 'h3' }, { title: 'Text', inline: 'p' }],
    content_css: '/css/app.css',
    setup: function setup(editor) {
      editor.on('init', function () {
        this.getDoc().body.style.fontSize = '14px';
        $("#mceu_13").css("display", "block");
        $("#descr_ifr").css("height", "280px");
      });
      editor.on('change', function () {
        tinymce.triggerSave();
      });
    },
    statusbar: false
  });

  function setPlainText() {
    var ed = tinyMCE.get('elm1');

    ed.pasteAsPlainText = true;

    //adding handlers crossbrowser
    if (tinymce.isOpera || /Firefox\/2/.test(navigator.userAgent)) {
      ed.onKeyDown.add(function (ed, e) {
        if ((tinymce.isMac ? e.metaKey : e.ctrlKey) && e.keyCode == 86 || e.shiftKey && e.keyCode == 45) ed.pasteAsPlainText = true;
      });
    } else {
      ed.onPaste.addToTop(function (ed, e) {
        ed.pasteAsPlainText = true;
      });
    }
  }

  //<!-- Load Facebook SDK for JavaScript -->

  (function (d, s, id) {
    var js,
        fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.8&appId=1821745098108701";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  $(".addMedia").click(function () {
    $(".mediaForm").show();
    $(".addMedia").hide();
  });

  var handle = $("#custom-handle");
  $("#slider").slider({
    min: 4,
    max: 12,
    value: $("#quantity").val(),
    create: function create() {
      handle.text($(this).slider("value"));
    },
    slide: function slide(event, ui) {
      handle.text(ui.value);
      $("#quantity").val(ui.value);
      if (typeof userIsLoggedIn == 'undefined') {
        if (ui.value > 4) {
          $("#register_div").show();
          $("#random_btn").attr('disabled', 'disabled');
          $("#random_btn").hide();
        } else {
          $("#register_div").hide();
          $("#random_btn").show();
          $("#random_btn").removeAttr('disabled');
        }
      }
    }
  });
  $(function () {
    $("#slider-range").slider({
      range: true,
      min: 1,
      max: 4,
      values: [1, 4],
      slide: function slide(event, ui) {
        $("#min_level").val(ui.values[0]);
        $("#max_level").val(ui.values[1]);
      }
    });
    $("#min_level, #max_level").on("change", function () {
      var max = $('#max_level').val();
      var min = $('#min_level').val();
      if (min > max) {
        $('#min_level').val(max);
      }
      $("#slider-range").slider("option", "values", [$("#min_level").val(), $("#max_level").val()]);
    });
  });
});

Vue.component('transition-list', {
  template: '\
  <div class="panel-body ">\
  <div class="col-md-12 well">\
  <div class="row">\
  <div class="col-sm-4  ">\
  <input type="text" v-model="search" class="form-control " placeholder="Filter Poses by Name or Keyword" autocomplete="off">\
  </div>\
  <div class="col-sm-4  "> \
  Show pose names <input	type="checkbox" v-model="showNames">\
  </div>\
  </div>\
  <i  v-show="loading" class="fa fa-spinner fa-5x fa-spin"></i>\
  <div>\
  <div class="thumbnail">\
  <div class="pose-container row is-flex">\
  <div  class=" col-sm-2  flex-item" v-show="!loading"   v-if="!multipleTrans.length >0" v-for="skill in filteredPoses"   >\
  <i title="Change left/right form of pose" class=" fa fa-refresh" aria-hidden="true" v-if="skill.symetric ==0"  v-on:click="toggleImg(skill)"  ></i> \
  <img  data-placement="auto bottom" v-bind:title="skill.pose" v-on:click="addPose(skill)" class="icon curser_pointer"  v-bind:src="skill.file" > \
  <small v-if="showNames">{{skill.pose}}</small>\
  </div>\
  <div v-show="noNext">\
  <h3 ><i class="fa fa-ban" aria-hidden="true"> There is no next pose in the database. Change the last pose from your sequence or save it and add a new transition.</i></h3>\
  </div>\
  </div>\
  </div>\
  <div v-if="!loading">\
  <h4 v-if="imagelist[0]">The pose you want to choose next is missing in the database? <br><a class="curser_pointer" data-toggle="modal" data-target="#myModal"><i class="fa fa-plus-square" aria-hidden="true"></i> Add a new transition now!</a><small> (Save your sequence first!)</small></h4>\
  </div>\
  <div v-if="multipleTrans.length >0">\
  <h2>There are multiple variations for this transitions in the database. Choose one:</h2>\
  <div v-on:click="addTrans(trans)" class="well curser_pointer "  v-for="trans in multipleTrans">{{trans.title}}\
  </div>\
  </div>\
  </div>\
  </div>\
  <div>\
  <h3 v-if="imagelist[0]" >Your Sequence</h3>\
  <div v-if="imagelist[0]" class="my-continer\ ">\
  <div id="scroll" class="well horizontally-scrolled-items">\
  <div class="flex-item" v-for="(image, index) in imagelist">\
  <img v-bind:src="image">\
  </div>\
  <i class="curser_pointer fa fa-2x fa-trash-o align-left" aria-hidden="true" v-if="imagelist[0]" v-on:click="deletePose()"></i> \
  </div>\
  <div class="col-sm-3" v-show="transarray.length>1 && auth">\
  <input required type="text" v-model="sequence_name" class="form-control " placeholder="Sequence Name" autocomplete="off">\
  </div>\
  <div class="col-sm-4" v-if="transarray.length>1 && auth">\
  <button :disabled="sequence_name.length <1" title="Give your sequence a name so save it!" class="btn btn-warning form-control" v-on:click="saveSequence(sequence, 0)"><i class="fa fa-eye-slash" aria-hidden="true"></i> Save Sequence to my profile only</button> \
  </div>\
  <div class="col-sm-4" v-if="transarray.length>1 && auth">\
  <button :disabled="sequence_name.length <1" title="Give your sequence a name so save it!" class="btn btn-warning form-control" v-on:click="saveSequence(sequence, 1)"><i class="fa fa-eye" aria-hidden="true"></i> Save Sequence to public database</button> \
  </div>\
  <div class="col-sm-12" >\
  <small v-if="sequence_name.length <1 && transarray.length>1 ">*Give your sequence a name so save it!</small>\
  <small v-if=" transarray.length<2 && auth">*Add min. 3 poses to save the sequence!</small>\
  <small v-if=" !auth ">*Login to save sequences!</small>\
  </div>\
  </div>\
  </div>\
  </div>',
  data: function data() {
    return {
      skills: [],
      sequence: [],
      sequenceSym: [],
      transarray: [],
      imagelist: [],
      loading: false,
      noNext: false,
      showNames: false,
      multipleTrans: [],
      search: '',
      sequence_name: '',
      auth: false
    };
  },

  computed: {
    filteredPoses: function filteredPoses() {
      var self = this;
      return this.skills.filter(function (x) {
        return x.pose.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
      });
      return this.skills;
    }
  },

  methods: {
    addPose: function addPose(skill) {
      this.loading = true;
      this.noNext = false;
      this.sequence.push(skill.id);
      if (skill.file == skill.filename) {
        this.sequenceSym.push(0);
      } else {
        this.sequenceSym.push(1);
      }
      this.imagelist.push(skill.file);

      this.search = '';
      var data = {
        lastPose: this.sequence[this.sequence.length - 2],
        currentPose: skill.id

      };
      $.getJSON('/api/v1/sequence/nextstep/', data, function (response) {
        // undefined means that there is no next pose for the current post
        if (response.nextSteps == undefined) {
          this.noNext = true;
          this.loading = false;
          this.skills = [];
        }
        // if sequence length is <2, dann ist nur eine pose in der liste noch keine sequence
        else if (this.sequence.length < 2) {
            this.skills = response.nextSteps;
            this.loading = false;
            // if response.transition >1 is then there are multipleTransitions
          } else if (response.transition) {
            if (response.transition.length > 1) {
              this.imagelist.pop();
              this.multipleTrans = response.transition;
              this.loading = false;
            } else {
              this.transarray.push(response.transition);
              this.skills = response.nextSteps;
              this.loading = false;
            }
          }
      }.bind(this));
    },
    addTrans: function addTrans(trans) {
      this.loading = true;
      this.multipleTrans = [];
      var data = {
        lastPose: trans.prev_id,
        currentPose: trans.next_id,
        transition: trans.id
      };
      $.getJSON('/api/v1/sequence/nextstep/', data, function (response) {
        if (this.sequence.length < 2) {
          this.imagelist.push(response.lastPoseImage);
          this.skills = response.nextSteps;
          this.loading = false;
        } else if (response.transition) {
          if (response.transition.length > 1) {
            this.multipleTrans = response.transition;
            this.loading = false;
          } else {
            this.transarray.push(response.transition);
            this.imagelist.push(response.lastPoseImage);
            this.skills = response.nextSteps;
            this.loading = false;
          }
        }
      }.bind(this));
    },
    toggleImg: function toggleImg(skill) {
      if (skill.file == skill.filename) {
        skill.file = skill.filename2;
      } else {
        skill.file = skill.filename;
      }
    },
    deletePose: function deletePose() {
      this.loading = true;
      this.transarray.pop();
      this.imagelist.pop();
      this.sequence.pop();
      this.sequenceSym.pop();
      var data = {
        currentPose: this.sequence[this.sequence.length - 1]
      };
      $.getJSON('/api/v1/sequence/nextstep/', data, function (response) {
        this.skills = response.nextSteps;
        this.loading = false;
      }.bind(this));
    },

    saveSequence: function saveSequence(sequence, publish) {

      axios.post('/api/saveSequence', {
        publish: publish,
        sequence: sequence.join('-'),
        idArray: sequence,
        sequenceSym: this.sequenceSym,
        transArray: this.transarray,
        transString: this.transarray.join('-'),
        sequence_name: this.sequence_name
      }).then(function (response) {
        //  console.log(response);
        window.location = window.location.origin + "/sequences/" + response.data.sequence.id9;
      }).catch(function (error) {
        console.log(error);
      });
    }
  },
  created: function created() {
    this.loading = true;
    var data = {
      sequence: this.sequence,
      status: 'start'
    };
    $.getJSON('/api/v1/sequence/nextstep', data, function (response) {
      this.auth = response.auth;
      // console.log(this.auth);
      this.skills = response.nextSteps;
      this.loading = false;
      if (!this.auth) {
        $(function () {
          $("#dialog").dialog({
            dialogClass: "no-close",
            buttons: [{
              text: "OK",
              click: function click() {
                $(this).dialog("close");
              }
            }],
            modal: true,
            show: {
              effect: "blind",
              duration: 1000
            },
            hide: {
              effect: "blind",
              duration: 200
            }
          });
        });
      }
    }.bind(this));
  },
  updated: function updated() {
    $(".sameheight").height(Math.max.apply(null, $(".sameheight1").map(function () {
      return $(this).height();
    })));
    if (this.$el.querySelector("#scroll")) {
      var container = this.$el.querySelector("#scroll");
      container.scrollLeft = container.scrollWidth;
    }
  }
});

new Vue({
  el: '#root',
  data: {
    search: '',
    sequences: []
  },
  computed: {
    filteredSequences: function filteredSequences() {

      var self = this;
      return this.sequences.filter(function (x) {
        return x.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
      });
      return this.sequences;
    }
  },
  created: function created() {
    var _this = this;

    axios.get('/api/v1/sequences/index').then(function (response) {
      _this.sequences = response.data;
    });
  }
});

// new Vue({
//   el: '#req',
//   data: {
//     selected: '1',
//     sel: 0,
//     options: [
//       { text: '[Select Pose/Transition]', value: '0' },
//       { text: 'Pose', value: '1' },
//       { text: 'Transition', value: '2' },
//     ],
//     ajaxResponse:[],
//     search: '',
//     related: [],
//
//
//   },
//   created() {
//     axios.get('/requirements/getall')
//     .then(response => this.ajaxResponse =response.data);
//   },
//   methods: {
//     select: function select() {
//       this.search='';
//     },
//     selectItem: function selectItem(item) {
//       this.search=item.name;
//       axios.get('/requirements/getrelated', {
//         params: {
//           itemId: item.id,
//         }
//       }).then(response => this.related =response.data);
//        console.log(this.related);
//     }
//   },
//   computed:
//   {
//     filtered:function()
//     {
//       if (this.ajaxResponse.pose) {
//         var self=this;
//         if (this.selected =='1'){
//           var request= this.ajaxResponse.pose;
//         } else if (this.selected =='2'){
//           var request= this.ajaxResponse.trans;
//         }
//         return request.filter(function(x){return x.name.toLowerCase().indexOf(self.search.toLowerCase())>=0;});
//         return request;
//       }
//     }
//   }
// })

},{}]},{},[1]);
